import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import { WorkshopTypes } from './types';
import { mockEvents } from './mockEvents';

import type { Context, Teamup } from '../../types';

import introduccionALaImpro from './assets/introduccionALaImpro.jpg';
import escenasDeCine from './assets/escenasDeCine.jpg';
import meditacionPractica from './assets/meditacionPractica.jpg';
import finalesDeImpro from './assets/finalesDeImpro.jpg';
import reconecta from './assets/reconecta.jpg';
import tallerParaDocentes from './assets/tallerParaDocentes.jpg';
import superimproheroes from './assets/superimproheroes.jpg';
import babel from './assets/babel.jpg';
import improcampa from './assets/improcampa.jpg';
import accionEnLaEscena from './assets/accionEnLaEscena.jpg';
import proyectoCreativo from './assets/proyectoCreativo.jpg';
import interpretacion from './assets/interpretacion.jpg';
import improvisateUnTema from './assets/improvisateUnTema.jpg';
import clownConFran from './assets/clownConFran.jpg';
import narrativaCuerpo from './assets/narrativaCuerpo.jpg';
import storytelling from './assets/storytelling.jpg';
import standup from './assets/standup.jpg';
import improdeutsch from './assets/improdeutsch.jpg';
import fallback from './assets/fallback.jpg';

enum WorkshopType {
  Class,
  Workshop,
  Unknown,
}
enum WorkshopLanguage {
  Spanish,
  English,
}

interface ParsedTeamupEvent {
  title: string;
  type: WorkshopType;
  language: WorkshopLanguage;
  isHighlighted: boolean;
  image: {
    src: string;
    alt: string;
  };
  id: number;
  startDate: Date;
  endDate: Date;
  isOnline: boolean;
}

// Returns a workshop type from a title string.
// This is used so that we can distinguish between different workshops from the Teamup events,
// since there is no concept of "event type" in there.
const getWorkshopType = (inputTitle: string): WorkshopTypes | null => {
  // Remove any diacritics
  const title = inputTitle.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  if (title.match(/escenas/i)) return WorkshopTypes.EscenasDeCine;
  if (title.match(/medita/i)) return WorkshopTypes.MeditacionPractica;
  if (title.match(/finales/i)) return WorkshopTypes.FinalesDeImpro;
  if (title.match(/conecta/i)) return WorkshopTypes.ReConecta;
  if (title.match(/docen/i)) return WorkshopTypes.TallerParaDocentes;
  if (title.match(/heroes/i)) return WorkshopTypes.Superimproheroes;
  if (title.match(/babel/i)) return WorkshopTypes.Babel;
  if (title.match(/campa/i)) return WorkshopTypes.Improcampa;
  if (title.match(/acci/i)) return WorkshopTypes.AccionEnLaEscena;
  if (title.match(/creativo/i)) return WorkshopTypes.ProyectoCreativo;
  if (title.match(/interpretaci/i)) return WorkshopTypes.Interpretacion;
  if (title.match(/improvisate/i)) return WorkshopTypes.ImprovisateUnTema;
  if (title.match(/clown con fran/i)) return WorkshopTypes.ClownConFran;
  if (title.match(/narrativa del cuerpo/i)) return WorkshopTypes.NarrativaCuerpo;
  if (title.match(/storytelling/i)) return WorkshopTypes.Storytelling;
  if (title.match(/stand-up/i)) return WorkshopTypes.Standup;
  if (title.match(/deutsch/i)) return WorkshopTypes.Improdeutsch;

  return null;
};

const getWorkshopImage = (type: WorkshopTypes) => ({
  [WorkshopTypes.EscenasDeCine]: {
    src: escenasDeCine,
    alt: 'Escenas de Cine',
  },
  [WorkshopTypes.MeditacionPractica]: {
    src: meditacionPractica,
    alt: 'Meditación práctica',
  },
  [WorkshopTypes.FinalesDeImpro]: {
    src: finalesDeImpro,
    alt: 'Finales de Impro',
  },
  [WorkshopTypes.ReConecta]: {
    src: reconecta,
    alt: 'Re´conecta',
  },
  [WorkshopTypes.TallerParaDocentes]: {
    src: tallerParaDocentes,
    alt: 'Taller para Docentes',
  },
  [WorkshopTypes.Superimproheroes]: {
    src: superimproheroes,
    alt: 'SuperImproHeroes',
  },
  [WorkshopTypes.Babel]: {
    src: babel,
    alt: 'Babel Impro',
  },
  [WorkshopTypes.Improcampa]: {
    src: improcampa,
    alt: 'ESimproCampa',
  },
  [WorkshopTypes.AccionEnLaEscena]: {
    src: accionEnLaEscena,
    alt: 'Acción en la Escena',
  },
  [WorkshopTypes.ProyectoCreativo]: {
    src: proyectoCreativo,
    alt: '¡Monta tu proyecto creativo!',
  },
  [WorkshopTypes.Interpretacion]: {
    src: interpretacion,
    alt: 'Taller de Interpretación Teatral',
  },
  [WorkshopTypes.ImprovisateUnTema]: {
    src: improvisateUnTema,
    alt: 'Improvísate un tema',
  },
  [WorkshopTypes.ClownConFran]: {
    src: clownConFran,
    alt: 'Taller de Clown con Fran Contreras',
  },
  [WorkshopTypes.NarrativaCuerpo]: {
    src: narrativaCuerpo,
    alt: 'La narrativa del cuerpo',
  },
  [WorkshopTypes.Storytelling]: {
    src: storytelling,
    alt: 'Storytelling with Lee White',
  },
  [WorkshopTypes.Standup]: {
    src: standup,
    alt: 'Stand-up with Carlos Montes',
  },
  [WorkshopTypes.Improdeutsch]: {
    src: improdeutsch,
    alt: 'Aprende alemán improvisando',
  },
}[type]);

const nameRegex = /ESIMPRO +(Escuela|School|Workshop) *- *([^\(]*) *(\(.*$)?/i;

const parseName = (name: string) => {
  const match = name.match(nameRegex);

  if (!match) {
    return null;
  }

  const flags = (match[3] || '').split('(').map(flag => (
    flag.replace(')', '')
  ));

  const title = match[2];

  const type = match[1].match(/escuela|school/i)
      ? WorkshopType.Class
      : match[1].match(/workshop/i)
        ? WorkshopType.Workshop
        : WorkshopType.Unknown;

  const language =
    // Check flags first
    flags.reduce<null | WorkshopLanguage>((f, flag) => (
      f !== null
        ? f
        : flag.match(/es/i)
        ? WorkshopLanguage.Spanish
        : flag.match(/en/i) ?
        WorkshopLanguage.English
        : null
    ), null)
    // Otherwise look for 'school' in the name
    || (match[1].match(/school/i) && WorkshopLanguage.English)
    // Otherwise fallback to spanish
    || WorkshopLanguage.Spanish;

  const isHighlighted =
    (flags.some(flag => flag.match(/\*/i))
    || Boolean(match[1].match(/workshop/i))) && !(
      title.match(/playing/i) || title.match(/conecta/i)
    );

  const workshopType = getWorkshopType(title);

  const image = title.match(/introducci/i) ? {
    src: introduccionALaImpro,
    alt: 'Introducción a la impro',
  } : workshopType ? getWorkshopImage(workshopType) : {
    src: fallback,
    alt: 'Workshops',
  };

  return {
    title,
    type,
    language,
    isHighlighted,
    image,
  };
};

// Gets the teamup events, either from the data storage in production, or the mock events otherwise
const getEvents = (data: Context['data']) =>
  process.env.NODE_ENV === 'production'
    ? data.teamupEvents
    : mockEvents;

// Extracts data from the teamup event
const parseTeamupEvent = (workshop: Teamup.Event): null | ParsedTeamupEvent => {
  const nameData = parseName(workshop.name);
  if (!nameData) return null;

  return {
    ...nameData,
    id: workshop.id,
    startDate: new Date(workshop.start_time),
    endDate: new Date(workshop.end_time),
    isOnline: workshop.venue.is_online,
  };
};

// Filters Teamup events based on:
//   - Valid (non-null) and non-empty title
//   - Highlighted events
//   - Events whose start date is still in the future
const filterEvent = (event: null | ParsedTeamupEvent): event is ParsedTeamupEvent =>
  Boolean(event && event.title && event.isHighlighted && event.startDate > new Date());

// Sorts events based on their start dates
const sortEvents = (a: ParsedTeamupEvent, b: ParsedTeamupEvent) =>
  differenceInMilliseconds(a.startDate, b.startDate);

// Returns the set of events prepared to be rendered
const getTeamupEvents = (data: Context['data']) =>
  getEvents(data)
    .map(parseTeamupEvent)
    .filter(filterEvent)
    .sort(sortEvents);

export {
  getTeamupEvents,
  parseTeamupEvent,
  getWorkshopImage,
};
